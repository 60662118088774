<template>
  <v-dialog
    v-model="infoModal"
    width="80%"
    @click:outside="$emit('update:infoModal', false)"
  >
    <v-card :dark="$dark.get()" style="border-radius: 30px">
      <v-card-title> </v-card-title>
      <v-card-text class="modal">
        <div class="modal_top">
          <!-- блок с информацией -->
          <div class="modal_text">
            <div class="modal__item">
              <span class="modal_title">Название товара: </span
              >{{ redactingObj.providerProductId.name }}
            </div>
            <div class="modal__item">
              <span class="modal_title">Короткое описание: </span>
              {{ redactingObj.providerProductId.shortDescription }}
            </div>
            <div class="modal__item">
              <span class="modal_title">Полное описание: </span>
              {{ redactingObj.providerProductId.description }}
            </div>
            <div class="modal__item">
              <span class="modal_title">Guid: </span>
              {{ redactingObj.providerProductId.guid }}
            </div>
            <div class="modal__item">
              <span class="modal_title">Штрихкоды: </span>
              <span
                v-for="barcode in redactingObj.providerProductId.barcodes"
                :key="barcode.barcode"
              >
                {{ barcode.barcode }}
              </span>
            </div>
          </div>
          <!-- блок с картинкой -->
          <div class="modal_image">
            <v-img
              v-if="redactingObj.providerProductId.images.length"
              :src="redactingObj.providerProductId.images[0].fileUrl"
            />
            <p v-else>Нет фото</p>
          </div>
        </div>
        <div class="modal_bot">
          <span class="modal_title">Информация по филиалам</span>
          <v-simple-table>
            <thead>
              <tr>
                <th class="table_title">Филиал</th>
                <th class="table_title">Остаток</th>
                <th class="table_title">Цена</th>
                <th class="table_title">Со скидкой</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="departmentObj in redactingObj.prices"
                :key="departmentObj.id"
              >
                <td>{{ departmentObj.departmentId }}</td>
                <td>{{ departmentObj.balance }}</td>
                <td>{{ departmentObj.price }}</td>
                <td>{{ departmentObj.discount ? "Да" : "Нет" }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
      <v-card-actions class="flex justify-center">
        <v-btn right @click="$emit('update:infoModal', false)">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InfoDialog",
  props: {
    infoModal: {
      type: Boolean,
      default: false,
    },
    redactingObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  padding: 20px;
  &_top {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &_bot {
    width: 100%;
  }
  &_image {
    width: 30%;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
  }
  &_text {
    width: 65%;
  }
  &_title {
    color: black;
    font-weight: 700;
  }
}
.table_title {
  color: #5d4bd0 !important;
  font-weight: bold !important;
}
</style>
